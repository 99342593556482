<template>
   <section>
  <div  v-for="paket in paket" v-bind:key="paket.id">
    <b-form
      method="post"
      enctype="multipart/form-data"
      cols="12"
     
    >
      <b-row >
        <b-col md="8">
          <b-card >
            <b-card-header
              style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
            >
              <div class="d-flex align-items-center">
                <b-card-title>
                  <h4><b style="color: #7a2a85">Form Pembayaran</b></h4>
                </b-card-title>
              </div>
            </b-card-header>

            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default">
                  <b>Kode Voucher </b>
                </label>
                <b-form-input
                  id="title"
                  v-model="paket.nama_voucher"
                  placeholder="Kode Voucher"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"
                  ><b>Upload Bukti Transfer</b></label
                >
                <b-img
              center
              class="mb-1 mb-sm-0"
              height="200"
              :src="getUrl + 'penyimpanan/transactionpakets/' + paket.photo"
            />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b>Catatan</b></label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Deskripsi"
                  v-model="paket.catatan"
                  rows="2"
                  disabled
                />
              </b-col>
            </b-row>
            <b-col md="6" xl="1" class="mb-1">
              <b-form-input
                hidden
                id="basicInput"
                v-model="paket.photo"
                placeholder="Name File"
              />
            </b-col>
            <b-col md="6" xl="1" class="mb-1">
              <b-form-input
              hidden
                id="basicInput"
                v-model="paket.users_id"
                placeholder="ID User"
              />
            </b-col>
            <b-col md="6" xl="1" class="mb-1">
              <b-form-input
              hidden
                id="basicInput"
                v-model="paket.paket_id"
                placeholder="ID Paket"
              />
            </b-col>
          </b-card>
        </b-col>
        <b-col md="4">
          <div class="list-view product-checkout mt-0">
            <div class="checkout-options">
              <b-card>
                <h4><b style="color: #7a2a85">Detail Pembayaran</b></h4>

                <hr />
                <div class="price-details">
                  <h6 class="price-title"></h6>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Durasi Paket:</p>
                    <p class="invoice-date">
                      <b style="color: red">{{paket.durasi}} hari</b>
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Harga Paket:</p>
                    <p class="invoice-date">
                      <b style="color: red"
                        >Rp {{ formatPrice(paket.price_paket) }}</b
                      >
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Diskon Voucher:</p>
                    <p class="invoice-date">
                      <b style="color: red"
                        >Rp {{ formatPrice(paket.price_discount) }}</b
                      >
                    </p>
                  </div>
                  <hr />
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Total Pembayaran</p>
                    <p class="invoice-date">
                      <b style="color: red">Rp {{ formatPrice(paket.price_total) }}</b>
                    </p>
                  </div>
                  <br/>
                </div>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: #7a2a85">Bukti Transaksi</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>

      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <b-img
                      ref="previewEl"
                      rounded
                      :src="require('@/assets/images/avatars/13-small.png')"
                      height="50"
                    />
                    <h3 class="text-primary invoice-logo">Optimal</h3>
                  </div>
                  <p class="card-text mb-25">
                    Grand Slipi Tower 5 Unit F Jalan S. Parman Kav. 22-24 Kel.
                    Palmerah, Kec. Palmerah Jakarta Barat
                  </p>
                  <p class="card-text mb-25">DKI Jakarta, Indonesia, 11480</p>
                  <p class="card-text mb-0">Telp. +62 813 8656 5646</p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Invoice
                    <span class="invoice-number">#{{ paket.id }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Tgl Bayar:</p>
                    <p class="invoice-date">
                      {{ paket.tgl_transaksi }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Tempo:</p>
                    <p class="invoice-date">
                       1 x 24 Jam 
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">Diberikan Ke:</h6>
                  <h6 class="mb-25">{{ paket.nama_user }}</h6>
                  <p class="card-text mb-25">{{ paket.kampus_user }}</p>
                  <p class="card-text mb-25">{{ paket.email_user }}</p>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite responsive :items="pakets" :fields="fields">
              <!-- :fields="['title', 'description', 'price']" -->
              <template #cell(title)>
                <b-card-text class="font-weight-bold mb-25">
                  {{paket.title}}
                </b-card-text>
              </template>
              <template #cell(description)>
                <b-card-text class="font-weight-bold mb-25">
                  {{paket.description}}
                </b-card-text>
              </template>
              <template #cell(price)>
                <b-card-text class="font-weight-bold mb-25">
                  Rp {{ formatPrice(paket.price_paket) }}
                </b-card-text>
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Oleh:</span>
                    <span class="ml-75">Team Optimal</span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Subtotal:</p>
                      <p class="invoice-total-amount">
                        {{ formatPrice(paket.price_paket) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Discount:</p>
                      <p class="invoice-total-amount">
                        {{ formatPrice(paket.price_discount) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Tax:</p>
                      <p class="invoice-total-amount">0%</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">
                        Rp {{ formatPrice(paket.price_total) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Catatan: </span>
              <span>Cek Aktivasi Akun Setelah Pembayaran</span>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</section>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BFormFile,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BFormInput,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BRow,
  BInputGroup,
  BInputGroupAppend,
  BTableLite,
  BImg,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BImg,
    BFormInput,
    quillEditor,
    BForm,
    BFormFile,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTableLite,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BNavItem,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      paket: [],
      pakets: [],
      fields: ["title", "description", "price"],
      nama: localStorage.getItem("Unama"),
      email: localStorage.getItem("Uemail"),
      kampus: localStorage.getItem("Ukampus"),
      id_session: localStorage.getItem("Uid"),
      cekCodeVoucher: [],
      id_transaksipaket: this.$route.params.id,
      paket_id: "",
      users_id: "",
      getUrl: "",
      DateNow: "",
    };
  },
 
  async created() {
    await this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `transactionpakets/view2/${this.$route.params.id}`
      )
      .then((response) => {
        this.paket = response.data.data;
        // this.pakets =  Object.values(this.paket);
        this.pakets = Array.of(this.paket);
        // console.log("pakets", this.pakets);
      });

  },
  async mounted() {
    this.checkAuth();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
                    this.$router.push({ name: "login" });
      }
      else if(levelCheck != 2){
            this.$router.push({ name: "dashboard" });
      }
    },
    async terimaData(paket) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("users_id", (this.users_id = paket.users_id));
      formData.append("paket_id", (this.paket_id = paket.paket_id));

      await Swal.fire({
        title: "Apa data diterima?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, diterima!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `transactionpakets/update_terima/${this.$route.params.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.progressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    );
                  }.bind(this),
                }
              )
              .then((result) => {
                this.$router.push({ name: "list_approval_akun" });
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    async tolakData(paket) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("id_transaksipaket", this.id_transaksipaket);
      formData.append("users_id", (this.users_id = paket.users_id));
        formData.append("paket_id", (this.paket_id = paket.paket_id));

      await Swal.fire({
        title: "Apa data ditolak?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, tolak!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `transactionpakets/update_tolak/${this.$route.params.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.progressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    );
                  }.bind(this),
                }
              )
              .then((result) => {
                this.$router.push({ name: "list_approval_akun" });
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>